import { HomeContainer } from "./HomeContainer";
import { Route, Routes } from "react-router";
import {
  AssessmentSessionAdministrationRoutes,
  AssessmentSessionRoutes,
  AssessmentSessionViewRoutes,
} from "@idsk/assessment-session";
import { ResumeBuilderRoutes } from "@idsk/resume-builder";

export const RootContainer = () => {
  return (
    <>
      <Routes>
        <Route
          path="/clients/:clientId/assessment-sessions/:id/*"
          element={<AssessmentSessionRoutes />}
        />
        <Route
          path="/reports/clients/:clientId/assessment-sessions/:id/view/*"
          element={<AssessmentSessionViewRoutes />}
        />
        <Route
          path="/clients/:clientId/assessment-administration/:id/*"
          element={<AssessmentSessionAdministrationRoutes />}
        />
        <Route
          path="/clients/:clientId/resumebuilder/*"
          element={<ResumeBuilderRoutes />}
        />
        <Route path="*" element={<HomeContainer />} />
        <Route index element={<HomeContainer />} />
      </Routes>
    </>
  );
};
